import React, { useState, useEffect } from "react";
import Header from "../../components/Main/Header";
import { useUser } from "@clerk/clerk-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FAQPricing from "./FAQPricing";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const BuySubscription = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [plan, setPlan] = useState("monthly");
  const [quantity, setQuantity] = useState(1); // Quantity state
  const monthlyPricePerScreen = 16;
  const annualPricePerScreen = 160;

  // Calculate dynamic total price based on plan and quantity
  const totalPrice =
    plan === "monthly"
      ? monthlyPricePerScreen * quantity
      : annualPricePerScreen * quantity;

  useEffect(() => {
    if (user) {
      const checkSubscriptionStatus = async () => {
        try {
          const response = await axios.post(
            `${apiUrl}/sub/check-subscription-status`,
            {
              clerkId: user.id,
            }
          );

          const { isActive, message } = response.data;
          console.log("Statut de l'abonnement :", message);
          setHasActiveSubscription(isActive);

          if (isActive) {
            console.log("Utilisateur déjà abonné, redirection vers /");
            navigate("/");
          }
        } catch (error) {
          console.error(
            "Erreur lors de la vérification de l'abonnement :",
            error
          );
        }
      };

      checkSubscriptionStatus();
    }
  }, [user, apiUrl, navigate]);

  const handleToggle = () => {
    setPlan((prevPlan) => (prevPlan === "monthly" ? "annual" : "monthly"));
  };

  const handleQuantityChange = (delta) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + delta;
      return newQuantity < 1 ? 1 : newQuantity > 10 ? 10 : newQuantity;
    });
  };

  const handleSubscription = async () => {
    if (!user) {
      console.error("User information not available");
      return;
    }

    const stripePriceId =
      plan === "monthly"
        ? process.env.REACT_APP_STRIPE_PRICE_ID_LAUNCH_MONTHLY
        : process.env.REACT_APP_STRIPE_PRICE_ID_LAUNCH_ANNUAL;

    try {
      const sessionResponse = await axios.post(
        `${apiUrl}/sub/create-subscription-session`,
        {
          clerkId: user.id,
          email: user.primaryEmailAddress.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          planType: plan,
          quantity, // Make sure quantity is correctly passed here
        }
      );

      const sessionId = sessionResponse.data.sessionId;
      if (sessionId) {
        const stripe = window.Stripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Stripe session ID is missing or invalid");
      }
    } catch (error) {
      console.error("Error creating Stripe subscription session:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow bg-white py-8">
        {!hasActiveSubscription ? (
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center">
              <div className="text-center mb-16">
                <p className="inline-block font-semibold text-primary mb-4">
                  CHOIX
                </p>
                <h1 className="text-3xl md:text-4xl font-extrabold text-base-content">
                  Sélectionnez votre abonnement
                </h1>
              </div>

              {/* Plan Selector */}
              <div className="mb-8 flex items-center justify-center">
                <span
                  className={`text-lg font-semibold ${
                    plan === "monthly" ? "text-gray-900" : "text-gray-400"
                  }`}
                >
                  Mensuel
                </span>
                <label
                  htmlFor="plan-toggle"
                  className="flex items-center cursor-pointer mx-4"
                >
                  <div className="relative">
                    <input
                      id="plan-toggle"
                      type="checkbox"
                      className="sr-only"
                      checked={plan === "annual"}
                      onChange={handleToggle}
                    />
                    <div className="block w-14 h-8 rounded-full transition duration-300 ease-in-out bg-gray-200 relative">
                      <div
                        className={`absolute inset-0 w-14 h-8 rounded-full transition ${
                          plan === "annual" ? "bg-indigo-500" : ""
                        }`}
                      ></div>
                      <div
                        className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full shadow-md transform transition ${
                          plan === "annual" ? "translate-x-6" : ""
                        }`}
                      ></div>
                    </div>
                  </div>
                </label>
                <span
                  className={`text-lg font-semibold ${
                    plan === "annual" ? "text-gray-900" : "text-gray-400"
                  }`}
                >
                  Annuel
                </span>
              </div>

              {/* Pricing Display */}
              <div className="bg-white py-6" id="pricing">
                <div className="container mx-auto px-6">
                  <div className="flex justify-center">
                    <div className="bg-white border border-gray-200 rounded-lg shadow-lg p-8 max-w-md mx-auto text-center">
                      <h2 className="uppercase tracking-wide text-xl text-indigo-500 font-semibold">
                        Accès Anticipé
                      </h2>

                      <div className="text-5xl font-bold text-gray-900 mt-2">
                        {totalPrice.toFixed(2)}€
                        <span className="text-2xl font-normal text-gray-500">
                          {" "}
                          / {plan === "monthly" ? "mois" : "an"} pour {quantity}{" "}
                          {quantity > 1 ? "écrans" : "écran"}
                        </span>
                      </div>
                      <p className="text-gray-500 text-lg mt-1">
                        HT par {plan === "monthly" ? "mois" : "an"}
                      </p>
                      {plan === "annual" && (
                        <p className="text-green-600 mt-2">2 mois offerts!</p>
                      )}
                      <p className="text-gray-600 mt-4 mb-6">
                        Un tarif unique pour une gestion facile et efficace de
                        vos écrans.
                      </p>

                      {/* Features List */}
                      <ul className="space-y-2 text-center text-gray-700 mb-6 mx-auto max-w-xs">
                        <li className="flex items-center justify-center">
                          <span className="text-green-500 font-bold mr-2">
                            ✓
                          </span>
                          Diffusion d'images et vidéos
                        </li>
                        <li className="flex items-center justify-center">
                          <span className="text-green-500 font-bold mr-2">
                            ✓
                          </span>
                          Programmation de playlists
                        </li>
                        <li className="flex items-center justify-center">
                          <span className="text-green-500 font-bold mr-2">
                            ✓
                          </span>
                          Mise à jour de contenu à distance
                        </li>
                        <li className="flex items-center justify-center">
                          <span className="text-green-500 font-bold mr-2">
                            ✓
                          </span>
                          Support technique dédié
                        </li>
                      </ul>

                      {/* Quantity Selector */}
                      <div className="flex items-center mb-8 justify-center">
                        <button
                          onClick={() => handleQuantityChange(-1)}
                          className="bg-gray-200 px-3 py-1 rounded-l-md font-bold text-lg"
                        >
                          -
                        </button>
                        <span className="px-4 text-lg font-semibold">
                          {quantity}
                        </span>
                        <button
                          onClick={() => handleQuantityChange(1)}
                          className="bg-gray-200 px-3 py-1 rounded-r-md font-bold text-lg"
                        >
                          +
                        </button>
                      </div>

                      {/* Subscribe Button */}
                      <button
                        onClick={handleSubscription}
                        className="text-center text-white font-bold py-3 px-8 rounded-lg transition duration-200 ease-in-out bg-gradient-to-r from-indigo-500 to-pink-500 hover:bg-pink-500"
                      >
                        S'abonner maintenant
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* FAQ Component */}
              <div className="w-full">
                <FAQPricing />
              </div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                Abonnement Actif
              </h2>
              <p className="text-md text-gray-700 mb-8">
                Vous avez actuellement un abonnement actif.
              </p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default BuySubscription;
