import React, { useState } from "react";
import {
  FaDesktop,
  FaImages,
  FaThList,
  FaFileInvoice,
  FaCreditCard,
} from "react-icons/fa";
import { useUser } from "@clerk/clerk-react";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const Sidebar = ({ setActiveComponent }) => {
  const [activeTab, setActiveTab] = useState("myPlayers");
  const { user } = useUser(); // Récupération de l'utilisateur Clerk

  const buttonStyle =
    "flex items-center gap-2 p-2 w-full text-left rounded-lg-md text-slate-600 transition-colors duration-200 ease-in-out cursor-pointer";
  const activeButtonStyle = "bg-gray-200 rounded";
  const iconStyle = "w-5 h-5 mx-auto md:mx-0";
  const textClass = "hidden md:block";

  const handleSetActiveComponent = (componentName) => {
    console.log("[Sidebar] Changing active component to:", componentName);
    setActiveComponent(componentName);
    setActiveTab(componentName);
  };

  const redirectToCustomerPortal = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/stripe/create-customer-portal-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            clerkUserId: user.id, // Utilisation de l'ID utilisateur de Clerk
          }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        // Ouverture du portail client Stripe dans un nouvel onglet
        window.open(data.url, "_blank");
      } else {
        console.error("Erreur lors de la redirection:", data.error);
      }
    } catch (error) {
      console.error("Erreur lors de la redirection:", error);
    }
  };

  return (
    <div className="bg-white h-full flex-grow pt-10 w-16 pl-4 pr-4 md:w-64 flex flex-col justify-between border-r border-gray-300">
      <div>
        <nav>
          {/* Onglet Écrans */}
          <div
            onClick={() => handleSetActiveComponent("myPlayers")}
            className={`${buttonStyle} ${
              activeTab === "myPlayers" ? activeButtonStyle : ""
            }`}
          >
            <FaDesktop className={iconStyle} />
            <span className={textClass}>Écrans</span>
          </div>

          {/* Onglet Médias */}
          <div
            onClick={() => handleSetActiveComponent("myMedias")}
            className={`${buttonStyle} ${
              activeTab === "myMedias" ? activeButtonStyle : ""
            }`}
          >
            <FaImages className={iconStyle} />
            <span className={textClass}>Médias</span>
          </div>

          {/* Onglet Playlists */}
          <div
            onClick={() => handleSetActiveComponent("myPlaylists")}
            className={`${buttonStyle} ${
              activeTab === "myPlaylists" ? activeButtonStyle : ""
            }`}
          >
            <FaThList className={iconStyle} />
            <span className={textClass}>Playlists</span>
          </div>
        </nav>
      </div>

      {/* Onglets Factures et Abonnement */}
      <div className="mb-3">
        <nav>
          <div
            onClick={() => handleSetActiveComponent("myInvoices")}
            className={`${buttonStyle} ${
              activeTab === "myInvoices" ? activeButtonStyle : ""
            }`}
          >
            <FaFileInvoice className={iconStyle} />
            <span className={textClass}>Factures</span>
          </div>
          <div
            onClick={redirectToCustomerPortal}
            className={`${buttonStyle} ${
              activeTab === "mySubscription" ? activeButtonStyle : ""
            }`}
          >
            <FaCreditCard className={iconStyle} />
            <span className={textClass}>Abonnement</span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
