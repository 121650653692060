import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  SignIn,
  SignUp,
} from "@clerk/clerk-react";
import { frFR } from "@clerk/localizations";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import BuySubscription from "./pages/Subscription/BuySubscription";
import PaymentSuccess from "./pages/Stripe/PaymentSuccess";
import PaymentCancel from "./pages/Stripe/PaymentCancel";
import Changelog from "./pages/Changelog";
import WebPlayer from "./pages/WebPlayer";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));

const ClerkWithRoutes = () => {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      localization={frFR}
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
    >
      <Routes>
        {/* Clerk : Se connecter */}
        <Route
          path="/sign-in/*"
          element={
            <div className="flex items-center justify-center min-h-screen">
              <SignIn redirectUrl={"/"} routing="path" path="/sign-in" />
            </div>
          }
        />

        {/* Clerk : S'enregistrer */}
        <Route
          path="/sign-up/*"
          element={
            <div className="flex items-center justify-center min-h-screen">
              <SignUp redirectUrl={"/"} routing="path" path="/sign-up" />
            </div>
          }
        />

        {/* Landing Page */}
        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <App />
              </SignedIn>
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn redirectUrl={"/"} path="/sign-in" />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Tableau de Bord */}
        <Route
          path="/app"
          element={
            <>
              <SignedIn>
                <Dashboard />
              </SignedIn>
              <SignedOut>
                <Dashboard />
              </SignedOut>
            </>
          }
        />

        {/* Abonnement */}
        <Route
          path="/subscription"
          element={
            <>
              <SignedIn>
                <BuySubscription />
              </SignedIn>
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn redirectUrl={"/subscription"} />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Stripe : Payment Success */}
        <Route path="/success" element={<PaymentSuccess />} />

        {/* Stripe : Payment Cancel */}
        <Route path="/cancel" element={<PaymentCancel />} />

        <Route
          path="/web-player/:deviceId"
          element={
            <>
              <SignedIn>
                <WebPlayer />
              </SignedIn>
              <SignedOut>
                <SignIn redirectUrl={"/web-player"} />
              </SignedOut>
            </>
          }
        />
      </Routes>
    </ClerkProvider>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ClerkWithRoutes />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
