import React, { useState, useEffect, Suspense, lazy } from "react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import Header from "../components/Main/Header";
import GenericModal from "../components/Modals/GenericModal";
import Sidebar from "../components/Main/Sidebar";
import BuyPlayerModal from "../components/Modals/BuyPlayerModal";
import Changelog from "../pages/Changelog";
import { useNavigate } from "react-router-dom";

const MyPlayers = lazy(() => import("../components/Dashboard/MyPlayers"));
const MyMedias = lazy(() =>
  import("../components/Dashboard/MyMedias/MyMedias")
);
const MyPlaylists = lazy(() => import("../components/Dashboard/MyPlaylists"));
const MyInvoices = lazy(() => import("../components/Dashboard/MyInvoices"));
const MySubscription = lazy(() =>
  import("../components/Dashboard/MySubscription")
);
const MyCalendar = lazy(() => import("../components/Dashboard/MyCalendar"));

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const Dashboard = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("myPlayers");
  const [modalContent, setModalContent] = useState({});
  const [isBuyPlayerModalOpen, setIsBuyPlayerModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [licenseCount, setLicenseCount] = useState(0); // Ajouter l'état pour licenseCount

  // Fonction pour récupérer le statut d'abonnement de l'utilisateur
  const fetchSubscriptionStatus = async () => {
    console.log("Fetching subscription status for user:", user?.id);

    try {
      const response = await axios.post(
        `${apiUrl}/sub/check-subscription-status`,
        {
          clerkId: user.id,
        }
      );
      const { isActive, licenseCount, message } = response.data;

      // Mettez à jour licenseCount ici
      setLicenseCount(licenseCount); // Mise à jour du nombre de licences

      console.log("Subscription status:", isActive ? "active" : "inactive");
      console.log("License count:", licenseCount);
      console.log("Message:", message);

      if (!isActive) {
        console.warn(`Redirection vers /subscription : ${message}`);
        navigate("/subscription");
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'abonnement :", error);
      navigate("/subscription");
    }
  };

  useEffect(() => {
    if (user?.id) {
      console.log("User ID found:", user.id);
      fetchSubscriptionStatus();
    } else {
      console.log("User ID not available yet.");
    }
  }, [user?.id]);

  // Fonction pour afficher le composant actif avec Suspense
  const renderComponent = () => {
    switch (activeComponent) {
      case "myPlayers":
        return <MyPlayers licenseCount={licenseCount} />;
      case "myMedias":
        return <MyMedias />;
      case "myPlaylists":
        return <MyPlaylists />;
      case "myCalendar":
        return <MyCalendar />;
      case "myInvoices":
        return <MyInvoices />;
      case "mySubscription":
        return <MySubscription />;
      case "changelog":
        return <Changelog />;
      default:
        return <p>Tableau de bord Displex.</p>;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-grow overflow-hidden">
        <Sidebar setActiveComponent={setActiveComponent} />
        <main className="bg-gray-50 py-8 px-8 overflow-y-auto w-full">
          {isLoading ? (
            <div className="text-center">Chargement en cours...</div>
          ) : (
            <Suspense
              fallback={
                <div className="text-center">Chargement en cours...</div>
              }
            >
              {renderComponent()}
            </Suspense>
          )}
        </main>
      </div>
      {modalContent.isOpen && (
        <GenericModal
          isOpen={modalContent.isOpen}
          onClose={() => setModalContent({ ...modalContent, isOpen: false })}
          title={modalContent.title}
          content={modalContent.content}
          linkPath={modalContent.linkPath}
          linkText={modalContent.linkText}
        />
      )}
      {isBuyPlayerModalOpen && (
        <BuyPlayerModal
          isOpen={isBuyPlayerModalOpen}
          onClose={() => setIsBuyPlayerModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Dashboard;
